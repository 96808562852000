import React, { useEffect, useRef } from "react";
import "./Game.scss";
import Map from "../Map";
import DebugMarker from "../../containers/DebugMarkerContainer";
import Marker from "../../containers/MarkerContainer";
import { getColor, getCourse } from "../../util";

function Game({ raceResult, stream }) {
  const course = getCourse((stream && stream.stadium_name) || "");
  const map = useRef(null);
  const markers = useRef({});
  const style = {
    transform: `rotate(${course.rotation}deg)`
  };
  useEffect(() => {
    map.current = new Map(course);
  }, [course]);
  useEffect(() => {
    const competitionId =
      (stream && stream.competition && stream.competition.id) || null;
    const streamId = (stream && stream.id) || null;
    if (!streamId || !raceResult) return;
    raceResult.forEach(record => {
      const { car_id, category } = record;
      if (markers.current[car_id]) return;
      const markerInfo = {
        id: car_id,
        color: getColor(category),
        text: car_id
      };
      const markerParams = {
        competitionId,
        map: map.current,
        markerInfo,
        streamId,
        course
      };
      if (process.env.NODE_ENV !== "production") {
        new DebugMarker(markerParams);
      }
      markers.current[car_id] = new Marker(markerParams);
    });
  }, [course, raceResult, stream]);
  return (
    <div className="Game">
      <div id="map" className="Map" style={style} />
    </div>
  );
}

export default Game;
