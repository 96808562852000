class RaceResults {
  #memberInfosRef;
  #streamId;

  constructor(params) {
    const { streamId } = params;
    this.#streamId = streamId;
  }

  convertRecord = record => {
    const recordValues = record.split(",");
    return {
      rank: recordValues[0] || "-",
      thumb: recordValues[1] || "",
      subtitle: recordValues[2] || "",
      title: recordValues[3] || "データなし",
      time: recordValues[4] || "—’—’—”",
      car_id: recordValues[5] || null,
      category: recordValues[6] || "",
      group: recordValues[7] || "",
      car_thumb: recordValues[8] || "",
      profile_thumb: recordValues[9] || "",
      profile_description: recordValues[10] || ""
    };
  };

  convertMemberInfo = memberInfo => {
    if (!memberInfo || !memberInfo.csv) return null;
    const records = memberInfo.csv.split("\n") || [];
    return records.filter((_, i) => i > 0).map(r => this.convertRecord(r));
  };

  subscribe = callback => {
    this.#memberInfosRef = window.firebase
      .database()
      .ref(`v1/stats/${this.#streamId}/member_infos`);
    this.#memberInfosRef
      .orderByChild("member_type")
      .equalTo("race_result")
      .limitToFirst(1)
      .on("value", snapshot => {
        if (!snapshot.exists()) return;
        const memberInfo = Object.values(snapshot.val())[0];
        const raceResult = this.convertMemberInfo(memberInfo);
        callback(raceResult);
      });
  };

  unsubscribe = () => {
    this.#memberInfosRef && this.#memberInfosRef.off();
  };
}

export default RaceResults;
