class Streams {
  #streamsRef;
  #streamId;

  constructor(params) {
    const { streamId } = params;
    this.#streamId = streamId;
  }

  subscribe = callback => {
    this.#streamsRef = window.firebase
      .database()
      .ref(`v1/streams/${this.#streamId}`);
    this.#streamsRef.on("value", snapshot => {
      const stream = snapshot.val() || null;
      callback(stream);
    });
  };

  unsubscribe = () => {
    this.#streamsRef && this.#streamsRef.off();
  };
}

export default Streams;
