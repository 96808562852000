import React from "react";
import "./GameResultCarId.scss";
import { getColor } from "../../util";

function GameResultCarId({ car_id, category }) {
  const backgroundColor = getColor(category);
  return (
    <div className="GameResultCarId">
      <span className="Background" style={{ backgroundColor }}>
        <span className="CarId">{car_id}</span>
      </span>
    </div>
  );
}

export default GameResultCarId;
