function addOverlayToMap({ map, overlayBounds, overlayUrl }) {
  const options = { map };
  new window.google.maps.GroundOverlay(overlayUrl, overlayBounds, options);
}

function addCoordinateMarkersToMap({ coordinates, map }) {
  if (process.env.NODE_ENV === "production") return;
  coordinates.forEach(position => {
    const icon = {
      strokeColor: "white",
      path: window.google.maps.SymbolPath.CIRCLE
    };
    const options = { icon, position, map, zIndex: 0 };
    new window.google.maps.Marker(options);
  });
}

function createMap({ center, zoom }) {
  const isMobile = window.innerHeight > 192;
  const mapElement = document.getElementById("map");
  const options = {
    backgroundColor: "transparent",
    center,
    clickableIcons: false,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    draggable: false,
    keyboardShortcuts: false,
    styles: [{ featureType: "all", stylers: [{ visibility: "off" }] }],
    zoom: isMobile ? zoom.mobile : zoom.desktop
  };
  return new window.google.maps.Map(mapElement, options);
}

function Map({ center, coordinates, overlayBounds, overlayUrl, zoom }) {
  const map = createMap({ center, zoom });
  addOverlayToMap({ map, overlayBounds, overlayUrl });
  addCoordinateMarkersToMap({ coordinates, map });
  return map;
}

export default Map;
