function DebugMarker(map, rotation = 0) {
  const icon = {
    path: "M -2,-2 2,2 M 2,-2 -2,2",
    rotation: -rotation,
    scale: 3,
    strokeColor: "red",
    strokeWeight: 3
  };
  const options = { icon, map, zIndex: 1000 };
  return new window.google.maps.Marker(options);
}

export default DebugMarker;
