import { useState, useEffect } from "react";
import RaceResults from "../api/RaceResults";

function useRaceResult(streamId) {
  const [raceResult, setRaceResult] = useState(null);

  useEffect(() => {
    const raceResults = new RaceResults({ streamId });
    raceResults.subscribe(setRaceResult);
    return () => {
      raceResults.unsubscribe();
    };
  }, [streamId]);

  return raceResult;
}

export default useRaceResult;
