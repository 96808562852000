import {
  FUJI_SPEED_WAY,
  OKAYAMA_INTERNATIONAL_CIRCUIT,
  SPORTSLAND_SUGO,
  SUZUKA_CIRCUIT,
  TWIN_RING_MOTEGI
} from "./constants/courseName";
import fuji from "./courses/fuji-international-speedway.json";
import okayama from "./courses/okayama-international-circuit.json";
import sugo from "./courses/sportsland-sugo.json";
import suzuka from "./courses/suzuka-circuit.json";
import motegi from "./courses/twin-ring-motegi.json";

export function getColor(category) {
  switch (category) {
    case "ama":
      return "#FF9900";
    case "proam":
      return "#FFE500";
    default:
      return "#ffffff";
  }
}

export function getCourse(stadiumName) {
  switch (stadiumName) {
    case FUJI_SPEED_WAY:
      return fuji;
    case OKAYAMA_INTERNATIONAL_CIRCUIT:
      return okayama;
    case SPORTSLAND_SUGO:
      return sugo;
    case SUZUKA_CIRCUIT:
      return suzuka;
    case TWIN_RING_MOTEGI:
      return motegi;
    default:
      return fuji;
  }
}
