class Animation {
  #isRunning = false;
  #requestId;

  isRunning = () => this.#isRunning;

  start = callback => {
    if (this.#isRunning) return;
    let oldTimestamp = 0;
    const animationLoop = timestamp => {
      const diffTimeMS = timestamp - oldTimestamp;
      oldTimestamp = timestamp;
      callback(diffTimeMS);
      this.#requestId = requestAnimationFrame(animationLoop);
    };
    this.#requestId = requestAnimationFrame(animationLoop);
    this.#isRunning = true;
  };

  stop = () => {
    cancelAnimationFrame(this.#requestId);
    this.#isRunning = false;
  };
}

export default Animation;
