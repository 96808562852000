import React from "react";
import "./GameResultRank.scss";
import { ReactComponent as GoldTrophy } from "../../images/gold-trophy.svg";
import { ReactComponent as SilverTrophy } from "../../images/silver-trophy.svg";
import { ReactComponent as BronzeTrophy } from "../../images/bronze-trophy.svg";

function Rank({ rank }) {
  switch (rank) {
    case "1":
      return (
        <React.Fragment>
          <GoldTrophy />
          <span className="RankWithTrophy">1st</span>
        </React.Fragment>
      );
    case "2":
      return (
        <React.Fragment>
          <SilverTrophy />
          <span className="RankWithTrophy">2nd</span>
        </React.Fragment>
      );
    case "3":
      return (
        <React.Fragment>
          <BronzeTrophy />
          <span className="RankWithTrophy">3rd</span>
        </React.Fragment>
      );
    default:
      return <span className="Rank">{rank}</span>;
  }
}

function GameResultRank({ rank }) {
  return (
    <div className="GameResultRank">
      <Rank rank={rank} />
    </div>
  );
}

export default GameResultRank;
