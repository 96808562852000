import React from "react";
import "./GameResultCard.scss";
import GameResultCarId from "../GameResultCarId";
import GameResultRank from "../GameResultRank";

function GameResultCard({ car_id, category, group, rank, time, title }) {
  return (
    <li className="GameResultCard">
      <div className="Rank">
        <GameResultRank rank={rank} />
      </div>
      <div className="PlayerDetail">
        <div className="FirstLine">
          <GameResultCarId car_id={car_id} category={category} />
          <span className="Title">{title}</span>
          <time className="Time">{time}</time>
        </div>
        <span className="Group">{group}</span>
      </div>
    </li>
  );
}

export default GameResultCard;
