import React from "react";
import "./GameResult.scss";
import GameResultCard from "../GameResultCard";

function GameResult({ raceResult }) {
  if (!raceResult) return null;
  return (
    <ol className="GameResult">
      {raceResult.map(record => (
        <GameResultCard key={record.car_id} {...record} />
      ))}
    </ol>
  );
}

export default GameResult;
