import React from "react";
import { useParams } from "react-router-dom";
import BeforeGame from "../components/BeforeGame";
import Game from "../components/Game";
import GameResult from "../components/GameResult";
import useRaceResult from "../hooks/useRaceResult";
import useStream from "../hooks/useStream";

function GameContainer() {
  const { streamId } = useParams();
  const stream = useStream(streamId);
  const raceResult = useRaceResult(streamId);
  if (!stream) return null;
  if (stream.time_label === "レース開始前") {
    return <BeforeGame stream={stream} />;
  }
  if (stream.time_label === "レース終了") {
    return <GameResult raceResult={raceResult} />;
  }
  return <Game raceResult={raceResult} stream={stream} />;
}

export default GameContainer;
