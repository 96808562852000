class MarkerIcon {
  #backgroundColor = "#ffffff";
  #borderColor = "rgba(0, 0, 0, 0.2)";
  #canvas;
  #height = 0;
  #rotation = 0;
  #text = "";
  #textColor = "#000000";
  #width = 0;

  constructor({ color, height, rotation = 0, text, width }) {
    const scale = 3;
    this.#backgroundColor = color;
    this.#height = height * scale;
    this.#rotation = rotation;
    this.#text = text;
    this.#width = width * scale;
    this.#createIcon();
  }

  #createCanvas = () => {
    const canvas = document.createElement("canvas");
    canvas.width = this.#width;
    canvas.height = this.#height;
    return canvas;
  };

  #createIcon = () => {
    this.#canvas = this.#createCanvas();
    const context = this.#canvas.getContext("2d");
    context.translate(this.#width / 2, this.#height / 2);
    context.rotate((-this.#rotation / 180) * Math.PI);
    context.translate(-this.#width / 2, -this.#height / 2);
    context.beginPath();
    context.arc(
      this.#width / 2,
      this.#height / 2,
      (this.#width * 11) / 24,
      0,
      2 * Math.PI
    );
    context.closePath();
    context.fillStyle = this.#borderColor;
    context.fill();
    context.beginPath();
    context.arc(
      this.#width / 2,
      this.#height / 2,
      (this.#width * 3) / 8,
      0,
      2 * Math.PI
    );
    context.closePath();
    context.fillStyle = this.#backgroundColor;
    context.fill();
    context.font = `600 ${this.#width / 2}px "Inter", system-ui, sans-serif`;
    context.fillStyle = this.#textColor;
    context.textAlign = "center";
    context.fillText(this.#text, this.#width / 2, (this.#height * 2) / 3);
  };

  getUrl = () => {
    return this.#canvas.toDataURL("image/png");
  };
}

export default MarkerIcon;
