import { useState, useEffect } from "react";
import Streams from "../api/Streams";

function useStream(streamId) {
  const [stream, setStream] = useState(null);

  useEffect(() => {
    const streams = new Streams({ streamId });
    streams.subscribe(setStream);
    return () => {
      streams.unsubscribe();
    };
  }, [streamId]);

  return stream;
}

export default useStream;
