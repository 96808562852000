import React from "react";
import "./BeforeGame.scss";
import { ReactComponent as Fuji } from "../../images/fuji-speed-way.svg";
import { ReactComponent as Okayama } from "../../images/okayama-international-circuit.svg";
import { ReactComponent as Sugo } from "../../images/sportsland-sugo.svg";
import { ReactComponent as Suzuka } from "../../images/suzuka-circuit.svg";
import { ReactComponent as Motegi } from "../../images/twin-ring-motegi.svg";
import {
  FUJI_SPEED_WAY,
  OKAYAMA_INTERNATIONAL_CIRCUIT,
  SPORTSLAND_SUGO,
  SUZUKA_CIRCUIT,
  TWIN_RING_MOTEGI
} from "../../constants/courseName";

function getCourseImage(courseName) {
  switch (courseName) {
    case FUJI_SPEED_WAY:
      return Fuji;
    case OKAYAMA_INTERNATIONAL_CIRCUIT:
      return Okayama;
    case SPORTSLAND_SUGO:
      return Sugo;
    case SUZUKA_CIRCUIT:
      return Suzuka;
    case TWIN_RING_MOTEGI:
      return Motegi;
    default:
      return null;
  }
}

function BeforeGame({ stream }) {
  const CourseImage = getCourseImage((stream && stream.stadium_name) || "");
  if (!CourseImage) return null;
  return (
    <div className="BeforeGame">
      <CourseImage />
    </div>
  );
}

export default BeforeGame;
