import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ReactComponent as BackgroundParts } from "./images/background-parts.svg";
import Game from "./containers/GameContainer";
import NotFound from "./components/NotFound";
import "./App.scss";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="Background">
          <span className="BackgroundParts">
            <BackgroundParts />
          </span>
          <span className="BackgroundParts">
            <BackgroundParts />
          </span>
          <span className="BackgroundParts">
            <BackgroundParts />
          </span>
          <span className="BackgroundParts">
            <BackgroundParts />
          </span>
        </div>
        <Switch>
          <Route path="/:streamId">
            <Game />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
