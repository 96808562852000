import findIndex from "lodash/findIndex";
import Animation from "../Animation";
import Locations from "../api/Locations";
import {
  findNearestCoordinate,
  findNextCoordinate,
  computePosition,
  isValidDistance,
  isValidLocation,
  isWithinRange
} from "../geometry";
import Marker from "../components/Marker";

class MarkerContainer {
  #animationStart;
  #animationEnd;
  #coordinates;
  #location;
  #marker;
  #validLocationCount = 0;

  constructor({ competitionId, map, markerInfo, streamId, course }) {
    const { id } = markerInfo;
    this.#coordinates = course.coordinates;
    this.#marker = new Marker(markerInfo, map, course.rotation);
    const locations = new Locations({ carId: id, competitionId, streamId });
    locations.subscribe(this.#subscribeCallback);
    const animation = new Animation();
    animation.start(this.#animationCallback);
  }

  #animationCallback = diffTimeMS => {
    if (!this.#location) return;
    const distance = (this.#location.speed * diffTimeMS) / 1000;
    const position = computePosition({
      distance,
      from: this.#marker.getPosition(),
      to: this.#animationEnd
    });
    if (this.#shouldContinueAnimation(position)) {
      this.#setAnimationPoints(this.#animationEnd);
    }
    this.#setMarkerPosition(position);
  };

  #shouldContinueAnimation = position => {
    if (!position) return false;
    const isWithInAnimationBuffer = isWithinRange({
      from: this.#location,
      to: position,
      range: 50
    });
    const nearAnimationEnd = isWithinRange({
      from: position,
      to: this.#animationEnd,
      range: 1
    });
    if (this.#validLocationCount < 5) {
      return nearAnimationEnd;
    }
    return isWithInAnimationBuffer && nearAnimationEnd;
  };

  #setAnimationPoints = animationStart => {
    this.#animationStart = animationStart;
    this.#animationEnd = findNextCoordinate({
      coordinate: animationStart,
      coordinates: this.#coordinates
    });
  };

  #setMarkerPosition = position => {
    if (!position) return;
    this.#marker.setPosition(position);
  };

  #subscribeCallback = location => {
    if (!location) return;
    this.#location = location;
    const isInitial = !this.#animationStart && !this.#animationEnd;
    const nearestCoordinate = findNearestCoordinate({
      coordinate: location,
      coordinates: this.#coordinates
    });
    if (isValidLocation({ location, nearestCoordinate })) {
      this.#validLocationCount++;
    } else {
      this.#validLocationCount = 0;
    }
    if (
      !isInitial &&
      this.#validLocationCount > 5 &&
      !isValidDistance({
        coordinates: this.#coordinates,
        location,
        nearestCoordinate,
        previousCoordinate: this.#animationEnd
      })
    ) {
      return;
    }
    const nearestIndex = findIndex(this.#coordinates, nearestCoordinate);
    const animationEndIndex = findIndex(this.#coordinates, this.#animationEnd);
    if (
      isInitial ||
      (this.#validLocationCount > 5 && animationEndIndex < nearestIndex) ||
      this.#validLocationCount === 5
    ) {
      this.#setAnimationPoints(nearestCoordinate);
      this.#setMarkerPosition(nearestCoordinate);
    }
  };
}

export default MarkerContainer;
