import MarkerIcon from "./MarkerIcon";

const height = 24;
const width = 24;

function Marker(markerInfo, map, rotation) {
  const { id, color, text } = markerInfo;
  const markerIcon = new MarkerIcon({ color, height, rotation, text, width });
  const icon = {
    anchor: new window.google.maps.Point(width / 2, height / 2),
    scaledSize: new window.google.maps.Size(width, height),
    url: markerIcon.getUrl()
  };
  const options = { icon, map, optimized: false, zIndex: parseInt(id, 10) };
  return new window.google.maps.Marker(options);
}

export default Marker;
