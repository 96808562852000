import locationsSample from "./locations-sample";
import badConnectionSample from "./locations-sample-bad-connection";

const LOCATIONS_PATH = "v1/locations";

class Locations {
  #carId;
  #competitionId;
  #locationsRef;
  #streamId;
  #testIntervalId;

  constructor(params) {
    const { carId, competitionId, streamId } = params;
    this.#carId = carId;
    this.#competitionId = competitionId;
    this.#streamId = streamId;
  }

  #isDemo = () => [16730, 16731, 16732, 16733].includes(this.#streamId);

  subscribe = callback => {
    if (process.env.NODE_ENV === "production" && !this.#isDemo()) {
      this.#subscribeFirebase(callback);
    } else {
      this.#subscribeTest(callback);
    }
  };

  unsubscribe = () => {
    if (process.env.NODE_ENV === "production" && !this.#isDemo()) {
      this.#locationsRef && this.#locationsRef.off();
    } else {
      clearInterval(this.#testIntervalId);
    }
  };

  #subscribeFirebase = callback => {
    const startTime = new Date().getTime() / 1000;
    this.#locationsRef = window.firebase
      .database()
      .ref(`${LOCATIONS_PATH}/${this.#competitionId}/${this.#carId}`);
    this.#locationsRef
      .orderByChild("timestamp")
      .startAt(startTime)
      .on("child_added", snapshot => {
        const location = snapshot.val() || null;
        callback(location);
      });
  };

  #subscribeTest = callback => {
    let i = 0;
    const locations =
      this.#streamId === 16731 ? badConnectionSample : locationsSample;
    const fetchTime = this.#streamId === 16731 ? 2000 : 500;
    const distance = this.#streamId === 16733 ? 500 : 100;
    setTimeout(() => {
      this.#testIntervalId = setInterval(() => {
        const location = Object.values(locations)[i++] || null;
        callback(location);
      }, fetchTime);
    }, distance * this.#carId);
  };
}

export default Locations;
