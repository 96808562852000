import _computeDestinationPoint from "geolib/es/computeDestinationPoint";
import _findNearest from "geolib/es/findNearest";
import _getDistance from "geolib/es/getDistance";
import _getPathLength from "geolib/es/getPathLength";
import _getRhumbLineBearing from "geolib/es/getRhumbLineBearing";
import findIndex from "lodash/findIndex";

export function convertCoordinate(coordinate) {
  if (!coordinate) return null;
  const { lat, lng, latitude, longitude } = coordinate;
  if (typeof lat === "function" && typeof lng === "function") {
    return { lat: lat(), lng: lng() };
  }
  return { lat: lat || latitude, lng: lng || longitude };
}

export function findNearestCoordinate({ coordinate, coordinates }) {
  const position = convertCoordinate(coordinate);
  const nearest = _findNearest(position, coordinates);
  return convertCoordinate(nearest);
}

export function findNextCoordinate({ coordinate, coordinates }) {
  const index = findIndex(coordinates, coordinate);
  if (index === coordinates.length - 1) {
    return convertCoordinate(coordinates[0]);
  }
  return convertCoordinate(coordinates[index + 1]);
}

export function getDistance({ from, to }) {
  if (!from || !to) return 0;
  const fromCoordinate = convertCoordinate(from);
  const toCoordinate = convertCoordinate(to);
  return _getDistance(fromCoordinate, toCoordinate);
}

export function getBearing({ from, to }) {
  if (!from || !to) return 0;
  const fromCoordinate = convertCoordinate(from);
  const toCoordinate = convertCoordinate(to);
  return _getRhumbLineBearing(fromCoordinate, toCoordinate);
}

export function computeDestinationPoint({ bearing, distance, from }) {
  if (!from) return null;
  const fromCoordinate = convertCoordinate(from);
  return _computeDestinationPoint(fromCoordinate, distance, bearing);
}

export function computePosition({ distance, from, to }) {
  const maxDistance = getDistance({ from, to });
  const bearing = getBearing({ from, to });
  const nextPoint = computeDestinationPoint({
    bearing,
    distance: distance > maxDistance ? maxDistance : distance,
    from
  });
  if (!nextPoint) return null;
  return convertCoordinate(nextPoint);
}

export function isWithinRange({ from, to, range }) {
  if (!from || !to) return false;
  const distance = getDistance({ from, to });
  return distance <= range;
}

export function isValidLocation({ location, nearestCoordinate }) {
  if (!location || !nearestCoordinate) return false;
  return isWithinRange({ from: location, to: nearestCoordinate, range: 30 });
}

export function extractCoordinates({ coordinates, count, startIndex }) {
  return coordinates.concat(coordinates).splice(startIndex, count);
}

export function isValidDistance({
  coordinates,
  location,
  nearestCoordinate,
  previousCoordinate
}) {
  if (!location || !nearestCoordinate || !previousCoordinate) return false;
  const nearestIndex = findIndex(coordinates, nearestCoordinate);
  const previousIndex = findIndex(coordinates, previousCoordinate);
  const distance = getDistance({ from: previousCoordinate, to: location });
  const pathLength = _getPathLength(
    extractCoordinates({
      coordinates,
      count: nearestIndex - previousIndex,
      startIndex: previousIndex
    })
  );
  return Math.abs(distance - pathLength) < 30;
}
